import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CategoryModule } from '@/store/category';
import Browser from '@/support/browser';
import PlayerApi from '@/support/playerApi';
import { ServerDataModule } from '@/store/serverData';
import Util from '@/support/utility';
import { IWizardManager, ICategoryDto } from '@/interfaces';
import { EventBus } from '@/support/eventBus';

@Component
export default class CategoriesComponent extends Vue {
    options = {
        showAddNewCategory: Browser.getBoolParam('showAddNewCategory', false) || Browser.getBoolParam('show', false),
    };

    get serverData() {
        return ServerDataModule.serverData;
    }

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    loading = true;
    loaded = false;

    refresh = {
        lastRefreshCheck: new Date(),
        playIntervalMs: 20 * 1000, // playing check interval time
    };

    categories: ICategoryDto[] = [];

    $refs = {
        addNewCategory: null,
    };

    created() {
        Debug.setDebugModule('Categories', this);
    }

    async mounted() {
        Debug.log("categories mounted");

        await this.getCategoryList();

        this.loading = false;
        this.loaded = true;

        if (this.options.showAddNewCategory) {
            this.showAddCategory();
        }

        EventBus.Instance.$on(EventBus.CategoriesChanged, item => {
            this.getCategoryList();
        });

        return true;
    }

    async getCategoryList() {
        try {
            await CategoryModule.fetchCategories('categories');

            this.categories.splice(0, this.categories.length);

            CategoryModule.categories.forEach(category => {
                let catObj = <ICategoryDto>category;
                catObj.errors = [];
                catObj.busy = false;
                catObj.canMoveUp = true;
                catObj.canMoveDown = false;
                this.categories.push(catObj);
            });

            this.updateCanMove();

            Debug.log('getCategoryList', this.categories.length, 'Categories');

            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('getCategoryList', message);
            Util.showError('Failed to get Category list');
            return false;
        } finally {

        }
    }

    updateCanMove() {

        let last = this.categories.length - 1;
        for (let idx = 0; idx < this.categories.length; idx++) {
            let category = this.categories[idx];
            category.order = idx;
            category.canMoveUp = idx != 0;
            category.canMoveDown = idx != last;
        }
    }

    async moveUp(category: ICategoryDto, event: any = null) {
        Debug.log('categories moveUp', category);

        let idx = this.categories.indexOf(category);
        if (idx == -1) {
            Debug.error("moveUp invalid position", idx, category);
            Util.showError("Failed to move up");
            return false;
        }

        if (idx > 0) {
            this.categories.splice(idx, 1);
            this.categories.splice(idx - 1, 0, category);
            this.updateCanMove();

            try {
                category.busy = true;
                let response = await PlayerApi.moveCategoryUp(category);

                // TODO: Splice original list
                await CategoryModule.fetchCategories('categories');

                Debug.log('moveUp SUCCESS', response, category);
                return true;
            } catch (err) {
                let message = err.message || 'ERROR';
                Util.showError('Failed to move category up for ' + category.name + " error: " + message);
            } finally {
                this.getCategoryList();
            }
        }
    }

    async moveDown(category: ICategoryDto, event: any = null) {
        Debug.log('categories moveDown', category);

        let idx = this.categories.indexOf(category);
        if (idx == -1) {
            Debug.error("moveDown invalid position", idx, category);
            Util.showError("Failed to move down");
            return false;
        }

        if (idx < this.categories.length - 1) {
            this.categories.splice(idx, 1);

            if (this.categories.length == 1)
                this.categories.push(category);
            else
                this.categories.splice(idx + 1, 0, category);

            this.updateCanMove();

            try {
                category.busy = true;
                let response = await PlayerApi.moveCategoryDown(category);

                // TODO: Splice original list
                await CategoryModule.fetchCategories('categories');

                Debug.log('moveDown SUCCESS', response, category);
                return true;
            } catch (err) {
                let message = err.message || 'ERROR';
                Util.showError('Failed to move category down for ' + category.name + " error: " + message);
                return null;
            } finally {
                this.getCategoryList();
            }
        }
    }

    done() {
        this.wizard.back();
    }

    showAddCategory() {
        this.$refs.addNewCategory.show();
    }

    editCategory(category: ICategoryDto) {
        this.$refs.addNewCategory.show(category);
    }
}
